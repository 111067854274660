@font-face {
  font-family: 'Aeonik-Bold';
  src: url(./fonts/Aeonik-Bold.otf);
}

@font-face {
  font-family: 'Aeonik-Regular';
  src: url(./fonts/Aeonik-Regular.otf);
}

@font-face {
  font-family: 'Aeonik-Medium';
  src: url(./fonts/Aeonik-Medium.otf);
}


:root {
  --color-main-pitch-black: #231F20;
  --color-main-gray: #808080;
  --color-main-mist: #f1f1f1;
  --color-main-white: #ffffff;
  --color-main-unnatural-green: #8effc6;
  --color-main-twiceme: #00bfa5;
}

* {
  margin: unset;
  padding: unset;
  font-family: 'Aeonik-Regular', sans-serif;
}
